import './App.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import MainRoutes from './components/Routes/MainRoutes';
import { useEffect, useState } from 'react';
import Loader from './components/loader/Loader';



function App() {
  // const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 500)
  // }, [])

  return (
    <>
      <Toaster />
      <BrowserRouter>
        <MainRoutes />
      </BrowserRouter>
    </>
  );
}
export default App;
